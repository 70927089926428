import {Link, useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
import React, {useEffect} from "react";

const NotFoundPage = () => {
    const location = useLocation();
    const originalPath = location.state?.originalPath || location.pathname;

    useEffect(() => {
        ReactGA.event({
            category: "404",
            action: "Page not found event",
            label: originalPath,
        });
    }, [originalPath]);
    return (
        <div>
            <h2>404 - Page Not Found</h2>
            <p>The page you are looking for doesn't exist.</p>
            {originalPath && (<p>Requested path: {originalPath}</p>)}
            <p><Link to="/">Go to the main page</Link></p>
        </div>
    );
}
export default NotFoundPage