import {Authenticator, useAuthenticator, Button, Alert} from "@aws-amplify/ui-react";
import {
    confirmSignUp, signIn, SignInInput, SignInOutput,
    resendSignUpCode,
    ConfirmSignUpInput,
    ConfirmSignUpOutput,
    ResendSignUpCodeInput,
    ResendSignUpCodeOutput,
} from "@aws-amplify/auth";
import {Modal} from "react-bootstrap";
import {I18n} from 'aws-amplify/utils';
import {accessSearchDetailsPrompt, defaultHeader, termsSignUpFooter} from "../UIElements";
import {useUser} from "../../context/UserProvider/UserProvider";
import React, {useEffect, useState} from "react";
import {
    DefaultComponents
} from "@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents";
import CryptoJS from 'crypto-js';


I18n.setLanguage('en');
I18n.putVocabularies({
    en: {
        'Sign In': 'Log In',
        'Username/client id combination not found.': 'We couldn\'t find an account associated with that email address.',
    },
});

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY_FOR_VERIFY_EMAIL;

function encryptData(data: string): string {
    if (!SECRET_KEY) throw new Error("Secret key is not defined");
    return CryptoJS.TripleDES.encrypt(data, SECRET_KEY).toString();
}

function decryptData(ciphertext: string): string {
    if (!SECRET_KEY) throw new Error("Secret key is not defined");
    const bytes = CryptoJS.TripleDES.decrypt(ciphertext, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
}

const AuthModal = () => {
    const {authModalShow, setAuthModalShow, authenticatedUser} = useUser();
    const handleClose = () => {
        setAuthModalShow(null);
    };
    const {toSignUp} = useAuthenticator();
    const [resendCodeStatus, setResendCodeStatus] = useState('Resend Code');
    const [confirmStatus, setConfirmStatus] = useState('Confirm');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        if (authModalShow === "signUpClick") {
            toSignUp();
        }
    }, [authModalShow]);
    const services = {

        async handleSignIn(input: SignInInput): Promise<SignInOutput> {
            console.log("handleSignIn triggered!, ", input);
            try {
                const user = await signIn(input);
                console.log("handleSignIn success!, ", user);
                if (user.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
                    console.log("user not verified, redirecting to confirm sign up")
                    const encryptedInput = encryptData(JSON.stringify(input));
                    sessionStorage.setItem('loginInput', encryptedInput);
                }
                return user;
            } catch (error) {
                console.error("handleSignIn error: ", error);
                throw error;
            }
        },

        async handleConfirmSignUp(input: ConfirmSignUpInput): Promise<ConfirmSignUpOutput> {
            const {username, confirmationCode} = input;
            console.log("handleConfirmSignUp triggered!, ", username, " ,  ", confirmationCode,);
            setConfirmStatus('Confirming...');
            try {
                const encryptedInput = sessionStorage.getItem('loginInput');
                const loginInput = encryptedInput ? JSON.parse(decryptData(encryptedInput)) : null;
                const result = await confirmSignUp({username, confirmationCode});
                if (loginInput) {
                    await signIn(loginInput);
                }
                return result;
            } catch (error) {
                console.error(error);
                throw error;
            } finally {
                console.log("login input cleared from session storage");
                sessionStorage.removeItem('loginInput');
                setConfirmStatus('Confirm');
            }
        },

        async handleResendSignUpCode({username}: ResendSignUpCodeInput): Promise<ResendSignUpCodeOutput | undefined> {
            console.log("handleResendSignUpCode triggered!")
            setResendCodeStatus('Resending...');
            setIsButtonDisabled(true);
            try {
                const result = await resendSignUpCode({username})
                setIsButtonDisabled(false);
                return result;
            } catch (AmplifyError: any) {
                console.error(AmplifyError);
                if (AmplifyError.name === 'LimitExceededException') {
                    console.error('Limit for verification code to be resent exceeded. Please try after some time.');
                    setErrorMessage('Limit for verification code to be resent exceeded. Please try after some time.');
                } else {
                    console.error(AmplifyError);
                    alert(`An error occurred: ${AmplifyError.message}`);
                    setErrorMessage(`An error occurred: ${AmplifyError.message}`);
                }

            } finally {
                setResendCodeStatus('Resend Code');

            }
        }
    };

    const CustomResendCodeButton = ({onClick, resendCodeStatus, disabled}: any) => (
        <Button onClick={onClick} disabled={disabled}
                className="w-100">{resendCodeStatus}</Button>
    );

    const ErrorMessage = ({message}: any) => (
        <Alert isDismissible={true} variation={'error'} className={"pt-3"}>
            {message}
        </Alert>
    );

    const CustomConfirmSignUpFooter = () => {

        const {username} = useAuthenticator();

        const handleResendClick = () => {
            services.handleResendSignUpCode({username});
        };

        return (
            <div>
                <CustomResendCodeButton
                    onClick={handleResendClick}
                    resendCodeStatus={resendCodeStatus}
                    disabled={isButtonDisabled}
                />
                {errorMessage && <ErrorMessage message={errorMessage}/>}
            </div>
        );
    };

    const getComponents = (): DefaultComponents | undefined => {
        if (authModalShow) {
            const reason = authModalShow.endsWith("Click") ? "click" : "auto";
            const header = reason === "auto" ? accessSearchDetailsPrompt.Header : defaultHeader.Header;

            return {
                Header: () => <div>{header()}</div>,
                SignUp: {
                    Footer: () => <div>{termsSignUpFooter.SignUp.Footer()}</div>,
                },
                ConfirmSignUp: {
                    Footer: CustomConfirmSignUpFooter,
                },
            };
        }

        return undefined;
    };

    return (
        <Modal
            centered
            backdrop="static"
            className="authModal"
            size={"lg"}
            show={!!authModalShow}
            onHide={handleClose} id="authModal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body className={"p-0"}>
                {!!authModalShow && (
                    <Authenticator
                        signUpAttributes={['email']}
                        services={services}
                        formFields={{
                            signUp: {
                                email: {
                                    order: 1,
                                    isRequired: true,
                                    type: 'email',
                                    pattern: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$"
                                },
                                password: {order: 2},
                                confirm_password: {order: 3},
                            },
                        }}
                        components={getComponents()}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="pb-5 pt-3"></div>
            </Modal.Footer>
        </Modal>
    );
};

export default AuthModal;