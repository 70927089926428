import {useEffect} from 'react';
import ReactGA     from 'react-ga4';
import AppSettings from '../common/app-settings';
import {useUser}   from "../context/UserProvider/UserProvider";

const useGA = () => {

    const {authenticatedUser} = useUser();
    const userEmail = authenticatedUser?.email;

    useEffect(() => {
        const debugHostnames = AppSettings.debugHostnames;
        const isDebugEnv = debugHostnames.some(hostname => window.location.hostname.includes(hostname));
        const teamAccounts = AppSettings.teamAccounts;
        const isTeamAccount = teamAccounts.some(account => userEmail?.includes(account));
        console.log('User email:', userEmail);
        console.log("Authenticated user: ", authenticatedUser);
        if (!isDebugEnv && !isTeamAccount) {
            console.log('Initializing GA, hostname:', window.location.hostname);
            ReactGA.initialize(AppSettings.GAId);

            // Dynamically initialize GTM for production environments only
            const gtmScript = document.createElement('script');
            gtmScript.async = true;
            gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${AppSettings.GTMId}`; // Replace with your GTM
                                                                                               // ID
            document.head.appendChild(gtmScript);
        }
        if (isDebugEnv) {
            console.log('GA and GTM disabled for localhost');
        }
        if (isTeamAccount) {
            console.log('GA and GTM disabled for team accounts');
        }
    }, []);

    const sendGAPageView = (page: string, title: string) => {
        const debugHostnames = ['localhost', '127.0.0.1', '0.0.0.0', 'dev.personalitymap'];
        if (!debugHostnames.some(hostname => window.location.hostname.includes(hostname))) {
            ReactGA.send({hitType: 'pageview', page, title});
        }
    };

    const sendGAEvent = (category: string, action: string, label?: string, nonInteraction?: boolean) => {
        const debugHostnames = ['localhost', '127.0.0.1', '0.0.0.0', 'dev.personalitymap'];
        if (!debugHostnames.some(hostname => window.location.hostname.includes(hostname))) {
            ReactGA.send({
                             hitType      : 'event',
                             eventCategory: category,
                             eventAction  : action,
                             eventLabel   : label,
                             nonInteraction,
                         });
        }
    };

    const setGAUserId = (userId: string | null) => {
        ReactGA.set({user_id: userId});
    };

    return {
        sendGAPageView,
        sendGAEvent,
        setGAUserId,
    };
};

export default useGA;