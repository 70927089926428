import React from "react";
import Utils from "../common/utils";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Form from "react-bootstrap/Form";

import QuestionType from "../types/question.type";

const UI_STRINGS = require('../common/ui-strings.json')
const loading = require('../assets/DNA-1.9s-201px.gif');

const LoadingSimplest = () => <span style={{color: "grey"}}>...</span>

const LoadingSimple = () => {
    return <div style={{height: "100%"}} data-title="dot-flashing">
        <div className="stage">
            <div className="dot-flashing"></div>
        </div>
    </div>

}
const LoadingGif = (props: any) => {
    let width: string = "100px";
    if (props.width) {
        width = props.width + 'px';
    }
    return <div className='searchResultsDiv '>
        <img src={loading} alt="" width={width}/>
    </div>
}

interface SurpriseProps {
    what_is_surprising?: any
}

const Surprise = (props: SurpriseProps) => {
    let whatIsSurprising = UI_STRINGS.surprising_correlations[props.what_is_surprising]
    if (!whatIsSurprising) whatIsSurprising = props.what_is_surprising;
    return (
        <OverlayTrigger placement="bottom"
                        overlay={<Tooltip
                            id="button-tooltip-2">{Utils.capitalizeFirstLetter(whatIsSurprising)}</Tooltip>}
        >
            <span className="surprisingCell"><i className="bi bi-exclamation-diamond"></i></span>
        </OverlayTrigger>
    )
}

const OnlySurprisesSwitch = (props: any) => {
    const mostCorrelatedResult = props.mostCorrelatedResult;
    const toggleShowOnlySurprising = props.toggleShowOnlySurprising;
    const switchState = props.switchState;

    return (
        <div className="surprisingToggleWrapper lead ">
            {mostCorrelatedResult.filter((r: QuestionType) => r.score?.is_surprising == true).length > 0 && (
                <Form id="surprisingToggle">
                    <Form.Switch id="switchForShowOnlySurprising"
                                 className="d-flex align-items-bottom "
                                 label={UI_STRINGS.labels.show_only_surprises}
                                 onChange={toggleShowOnlySurprising}
                                 defaultChecked={switchState}/>
                </Form>
            )}
            {mostCorrelatedResult.filter((r: QuestionType) => r.score?.is_surprising == true).length == 0 && (
                <p>{UI_STRINGS.labels.no_surprises}</p>
            )}

        </div>)
}


const accessQuestionPage = {
    Header() {
        return (
            <h3 className="text-center mt-5 pt-5 pb-5 lead fs-2"
                style={{color: "hsl(190, 100%, 10%)"}}>{Utils.newlinesInJson(UI_STRINGS.accountRequired.accessQuestionPage)}</h3>


        );
    },
}


const emptyHeader = {
    Header() {
        return (
            <div className="pb-5 pt-3"></div>
        );
    },
}
const defaultHeader = {
    Header() {
        return (
            <h3 className="text-center ms-2 me-2 pt-1 pb-5 lead fs-2"
                style={{color: "hsl(190, 100%, 10%)"}}>{Utils.newlinesInJson(UI_STRINGS.labels.login_prompt)}</h3>


        );
    },
}

const accessSearchDetailsPrompt = {
    Header() {
        return (
            <h3 className="text-center pt-1 pb-5 lead fs-2"
                style={{color: "hsl(190, 100%, 10%)"}}>{Utils.newlinesInJson(UI_STRINGS.accountRequired.accessSearchDetails)}</h3>


        );
    },
}

const termsSignUpFooter = {
    SignUp: {
        Footer() {
            return (<div className="small p-4 pt-2">By creating an account you are agreeing to our <a
                href="https://personalitymap.io/terms" target='_blank'>Terms of
                Service</a> and <a href='https://personalitymap.io/privacy-policy'
                                   target='_blank'>Privacy
                Policy</a>. We may also send you occasional email updates, which you can unsubscribe from easily
                with
                one click.
            </div>)
        }
    }
}
const promptForQPage = Object.assign({}, accessQuestionPage, termsSignUpFooter);


export {
    LoadingSimplest,
    LoadingSimple,
    LoadingGif,
    Surprise,
    OnlySurprisesSwitch,
    promptForQPage,
    termsSignUpFooter, accessSearchDetailsPrompt, emptyHeader, defaultHeader,
}
