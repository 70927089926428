import Utils from "../../common/utils";
import React, {useEffect} from "react";
import UiConfig from "../../common/ui-config";

interface Correlation {
    text: string;
    correlation: number;
    is_surprising: boolean;
    what_is_surprising: string;
    actualCorrelations: any[];
}

interface ShareTextProps {
    stmt: string;
    shareUrl: string;
    correlations: Correlation[];
    regressionResult: any[];
    modelR2?: any;
    copiedText: string;
    mainText: string; // Adding `mainText` prop
}

const ShareTextComponent: React.FC<ShareTextProps> = ({
                                                          stmt,
                                                          mainText, // Destructure `mainText` prop
                                                          regressionResult,
                                                          copiedText
                                                      }) => {
    useEffect(() => {
    }, [copiedText, regressionResult]);

    useEffect(() => {
    }, [stmt]);

    const wrapURLs = (text: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex).map((part, index) =>
            part.match(urlRegex) ? (
                <span key={index} className="break-url">{part}</span>
            ) : (
                part
            )
        );
    };

    return (
        <div>
            {mainText.split('\n').map((line, index) => (
                <p key={index}>
                    {wrapURLs(line)}
                </p>
            ))}
        </div>
    );
};

export default ShareTextComponent;