import {useQuery} from "react-query";
import QuestionType from "../types/question.type";
import QuestionDataService from "../services/question.service";
import {useUser} from "../context/UserProvider/UserProvider";
import QuestionStatisticsType from "../types/question-statistics.type";
import StudyQuestionType from "../types/study-question.type";
import {useNavigate} from "react-router-dom";

export function useQuestionData(
    org_id: string,
    id: string,
    handleGetQuestionResult: (res: any) => void,
    handleErrorsFallback: any,
    originalSearch: string,
    baseQuestion: any,
    custom_text: any,
    handleStatisticsResult: (res: any) => void,
    displayStudyQsResult: (res: any) => void,
) {
    // todo: handleErrors only as a general fallback
    const {accountParams} = useUser();
    const navigate = useNavigate();

    function isAxiosError(error: any): error is { response: { status: number } } {
        return error?.response?.status !== undefined;
    }

    const {isFetching: isLoadingQuestion, refetch: getQuestion} = useQuery<QuestionType, Error>(
        "query-single-question",
        async () => {
            return await QuestionDataService.getQuestionById(org_id, id, accountParams);
        }, {
            enabled: false,
            retry: (failureCount, error) => {
                if (isAxiosError(error) && error.response.status === 404) {
                    console.log("Received 404, will not retry.");
                    return false;
                }
                return failureCount < 2;
            },
            staleTime: 1000 * 60 * 60 * 24, // 24 hours
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
            onSuccess: (res) => handleGetQuestionResult(res),
            onError: (err: any) => {
                handleErrors(err);
            },
        }
    )
    const {isFetching: isLoadingQStatistics, refetch: getQuestionStatistics} = useQuery<QuestionStatisticsType, Error>(
        "query-question-statistics",
        async () => {
            if (baseQuestion?.universal_q_id) {
                return await QuestionDataService.getQStatisticsPredictionById(org_id, id);

            }
            if (!baseQuestion?.universal_q_id && custom_text) {
                return await QuestionDataService.getStatisticsPredictionForText(org_id, custom_text);
            }
        }, {
            enabled: false,
            retry: 2,
            onSuccess: (res) => handleStatisticsResult(res),
            onError: (err: any) => handleErrors(err),

        }
    )

    const {
        isFetching: isLoadingQStudyQuestions,
        refetch: getQStudyQuestions
    } = useQuery<StudyQuestionType[], Error>(
        "query-question-study-questions",
        async () => {
            if (!custom_text) {
                return await QuestionDataService.getQStudyQuestions(org_id, id);
            }
        }, {
            enabled: false,
            retry: 2,
            onSuccess: (res) => displayStudyQsResult(res),
            onError: (err: any) => handleErrors(err),

        }
    )

    function handleErrors(err: any) {
        if (err.response.status === 404) {
            const originalPath = window.location.pathname;
            navigate("/404", {
                replace: true,
                state: {
                    originalSearch: originalSearch,
                    originalPath: originalPath,
                    startTime: new Date().getTime(),
                }
            });
        } else {
            handleErrorsFallback(err);
        }
    }

    return {
        isLoadingQuestion,
        getQuestion,
        isLoadingQStatistics,
        getQuestionStatistics,
        isLoadingQStudyQuestions,
        getQStudyQuestions
    };
}
