import React, {useEffect, useState} from "react";
import UiConfig from "../../common/ui-config";
import {Bar, BarChart, CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import FactorsDataType from "../../types/factorsDataType";
import wrap from 'word-wrap';

interface IChartData {
    name: string;
    value: number;
}

type Props = {
    data: FactorsDataType;
    factorsSet: any;
};

const FactorsChart = (props: Props) => {
    const {data} = {...props}
    const [factors, setFactors] = useState<any>(props.factorsSet)
    const subscales = data["subscales"]
    const [chartData, setChartData] = useState<IChartData[]>([])
    const [maxAbsValue, setMaxAbsValue] = useState<number>(0)

    const values: number[] = data["linear_regression"]

    function calcChartData() {
        if (subscales) {
            const chartData: IChartData[] = Object.keys(factors)
                .map((f: string) => {
                    let i = subscales?.indexOf(factors[f])
                    if (i !== undefined && i !== -1) {
                        return {
                            name: String(f),
                            value: isNaN(values[i]) ? 0 : Number(values[i].toFixed(2)),
                        }
                    } else {
                        return null; // Return null when 'i' is not present or -1
                    }
                })
                .filter(item => item !== null) as IChartData[]; // Filter out null values

            // ());
            setChartData(chartData);
        }
        if (subscales === undefined) {
            const chartData: IChartData[] = Object.keys(factors).map((label, index) => ({
                name: String(label),
                value: isNaN(values[index]) ? 0 : Number(values[index].toFixed(2)),
            }));
            setChartData(chartData);
        }

    }

    function getFactors() {
        let filterValues = subscales;  //values you want to keep
        let yourObject: { [k: string]: string } = UiConfig.factors.subscales;  //declare yourObject as an indexable type
        let filteredObject: { [key: string]: any } = {};

        if (filterValues) {
            let filteredKeys = Object.keys(yourObject)
                .filter(key => (filterValues?.includes(yourObject[key])) ?? false);

            filteredObject = filteredKeys.reduce((obj, key) => {
                obj[key] = yourObject[key];
                return obj;
            }, {} as { [key: string]: any });
        }
        setFactors(filteredObject);
    }


    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (chartData) {
            const maxAbsValue = Math.max(...chartData.map((obj: { value: number; }) => Math.abs(obj.value)), 0.5);
            setMaxAbsValue(maxAbsValue);
        }

    }, [chartData]);

    useEffect(() => {
        if (!subscales) {
            setFactors(props.factorsSet);
        }
        if (subscales) {
            getFactors();
        }
    }, [subscales]);


    useEffect(() => {
        calcChartData();
    }, [factors]);


    useEffect(() => {
        const updateViewportWidth = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', updateViewportWidth);

        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, []);


    const CustomizedXAxisTick = (props: any) => {
        const {x, y, payload} = props;
        const label = payload.value;

        // Splitting label into two lines if it exceeds a certain length
        let maxLabelLength: number;

        // Determine max label length based on viewport width using a switch statement
        switch (true) {
            case (viewportWidth < 500):
                maxLabelLength = 5;
                break;
            case (viewportWidth < 993):
                maxLabelLength = 12;
                break;
            case (viewportWidth < 1200):
                maxLabelLength = 15;
                break;
            default:
                maxLabelLength = 20;
                break;
        }
        const words: string[] = label.match(/[a-zA-Z0-9]+(?:[-/][a-zA-Z0-9]+)*/g); // Match words, hyphens, and slashes
        let lines: string[] = [''];

        words.forEach(word => {
            if (lines[lines.length - 1].length + word.length + 1 <= maxLabelLength) {
                lines[lines.length - 1] = lines[lines.length - 1] + word + ' ';
            } else if (word.length > maxLabelLength) {
                const newWords = word.match(new RegExp('.{1,' + maxLabelLength + '}', 'g')) || [];
                lines = [...lines, ...newWords];
            } else {
                lines.push(word + ' ');
            }
        });

        // Ensure each line has a minimum length of 3 characters
        for (let i = lines.length - 1; i > 0; i--) {
            while (lines[i].trim().length < 3 && lines[i - 1].trim().length > 3) {
                // Extract the last character of the previous line
                const lastChar = lines[i - 1].trim().slice(-1);

                // Remove the last character from the previous line and add it to the current line
                lines[i - 1] = lines[i - 1].trim().slice(0, -1);
                lines[i] = lastChar + lines[i];
            }
        }

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                    {lines.map((line, index) => (
                        <tspan key={index} x="0" dy={`${index === 0 ? 0.8 : 1}em`}>
                            {line.trim()}
                        </tspan>
                    ))}
                </text>
            </g>
        );
    };

    return (
        <ResponsiveContainer width="100%" height={350} className="">
            {chartData && (
                <BarChart
                    data={chartData}
                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                >

                    <ReferenceLine y={0} stroke="var(--theme-teal-dark)" strokeDasharray="3 3"/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" tick={<CustomizedXAxisTick/>}
                           angle={viewportWidth < 993 ? -30 : 0}
                           textAnchor={viewportWidth < 993 ? "end" : "middle"}
                           height={viewportWidth < 993 ? 160 : 100} interval={0}/>
                    <YAxis domain={[-maxAbsValue, maxAbsValue]}/>
                    {/*<Tooltip/>*/}
                    <Bar dataKey="value">
                        {
                            chartData.map((entry, index) => (
                                <Cell key={index} fill={entry.value >= 0 ? '#006f6c' : '#CC2F06'}/>
                            ))
                        }
                    </Bar>
                </BarChart>)}
        </ResponsiveContainer>
    );
}
export default FactorsChart;
