import CytoscapeComponent from 'react-cytoscapejs';
import React, {useEffect, useRef, useState} from "react";
import {LoadingSimple} from "../UIElements";
import setupCy from "./setupCy";
import cytoscape, {Stylesheet} from "cytoscape";
import {layouts} from "./layouts";

setupCy();

type VerboseDiagramProps = {
    elements: any[];
    layoutName: string;
    showCorrelation: boolean;
    edgeWidthCoefficient: number;
    nodesLimit: number;
    latestNodeId: string;
    reload?: boolean;
    loading: boolean;
    onLayoutApplied?: () => void;
    initialNodesNum: number,
};

const CytoscapeDiagram: React.FC<VerboseDiagramProps> = (props) => {
    const {
        elements,
        layoutName,
        showCorrelation,
        edgeWidthCoefficient,
        nodesLimit,
        latestNodeId,
        reload,
        loading,
        onLayoutApplied,
        initialNodesNum,
    } = props;
    console.log("elements: ", elements);
    const [layout, setLayout] = React.useState<any>(layouts[layoutName]);

    const cyRef = useRef<cytoscape.Core | null>(null);

    function getDefaultStylesheet() {
        console.log('Generating default stylesheet');
        return [{selector: "node", style: {label: "data(label)"}}];
    }

    const [stylesheet] = React.useState<any>(getDefaultStylesheet);

    const cytoscapeStylesheet: any[] = [
        {
            selector: "node",
            style: {
                "background-color": "#ffffff",
                "padding-top": "20",
                "padding-bottom": "20",
                "padding-left": "20",
                "padding-right": "20",
                "text-margin-y": -3,
                shape: "round-rectangle",
                "z-index": 10,
            }
        },
        {
            selector: "node[label]",
            style: {
                label: "data(label)",
                "font-size": "22",
                color: "black",
                "text-halign": "center",
                "text-valign": "center",
                "text-wrap": "wrap",
                "text-max-width": 300,
                "text-background-color": "white",
                "text-background-opacity": 1,
                "text-background-shape": "round-rectangle",
                "text-border-color": "white",
                "text-border-width": 1,
                "text-border-opacity": 1,
                "z-index": 20
            }
        },
        {
            selector: "edge",
            style: {
                label: showCorrelation ? 'data(label)' : '',
                'curve-style': 'bezier',
                'target-arrow-shape': 'none',
                'text-background-color': 'white',
                'text-background-opacity': 0.9,
                'text-background-padding': '4px',
                width: 1.5,
                "z-index": 5,
            }
        },
        {
            selector: "edge[label]",
            style: {
                label: 'data(label)',
                "z-index": 15,  // Ensure edge labels are on top of edges
            }
        },
        {
            selector: "edge:selected",
            style: {
                label: "data(label)",
                "font-size": "16",
                "text-background-color": "white",
                "text-background-opacity": 1,
                "text-background-padding": "2px",
                "text-margin-y": -4,
                "text-events": "yes",
                "z-index": 15
            }
        }
    ];


    const containerStyle = {
        width: '100%',
        height: '100%',
        marginTop: '1.5rem',
        padding: '1rem',
        borderTop: '1px lightgray solid',
    };

    function handleCy(cy: cytoscape.Core) {
        console.log('Cytoscape component initialized');
        cyRef.current = cy;
        bindCyEvents(cy);
        runLayout(cy, layoutName);

    }

    function bindCyEvents(cy: cytoscape.Core) {
        cy.on('select', 'edge', (event) => {
            event.target.addClass('selected');
        });
        cy.on('unselect', 'edge', (event) => {
            event.target.removeClass('selected');
        });

        cy.edges().forEach((edge) => {
            const weight = edge.data('weight');
            edge.style('width', weight * edgeWidthCoefficient);
        });
    }

    function handleNodeHighlight(cy: cytoscape.Core, latestNodeId: string) {
        console.log(`Nodes limit is above 6`);
        const latestNode = cy.elements(`node[id="${latestNodeId}"]`);
        const edges = cy.edges(`[source="${latestNodeId}"], [target="${latestNodeId}"]`);

        setTimeout(() => {
            console.log(`Highlighting latest node: ${latestNodeId}`);
            latestNode.style('color', 'white');
            edges.style('lineColor', 'white');

            setTimeout(() => {
                latestNode.style('color', 'black');
                edges.each((ele: cytoscape.EdgeSingular) => {
                    const labelValue = parseFloat(ele.data('label'));
                    if (labelValue < 0) {
                        ele.style('lineColor', 'red');
                    } else if (labelValue > 0) {
                        ele.style('lineColor', 'green');
                    }
                });
            }, 150);

        }, 600);
    }

    function updateElements(cy: cytoscape.Core, elements: any[]) {
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }
        elements.forEach((element) => {
            if (!element) return;
            if (element.group === 'nodes') {
                updateNode(cy, element);
            } else if (element.group === 'edges') {
                updateEdge(cy, element);
            }
        });
    }

    function updateNode(cy: cytoscape.Core, element: any) {
        if (!cy.elements(`node[id="${element.data.id}"]`).length) {
            cy.add({
                group: 'nodes',
                data: {id: element.data.id, label: element.data.label},
            });
        }
    }

    function updateEdge(cy: cytoscape.Core, element: any) {
        if (!cy.elements(`edge[id="${element.data.id}"]`).length) {
            cy.add({
                group: 'edges',
                data: {
                    id: element.data.id,
                    source: element.data.source,
                    target: element.data.target,
                    weight: element.data.weight,
                    label: element.data.label,
                },
            });
        }
    }

    function applyPresetLayout(cy: cytoscape.Core) {
        console.log('Applying preset layout');
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }
        const statement1 = cy.$('node[id="Statement1"]');
        const statement2 = cy.$('node[id="Statement2"]');
        const cyContainer = cy.container();
        const stmtContainer = cy.$('node[id="StatementContainer"]');
        let containerWidth = 600;
        let containerHeight = 400;
        if (cyContainer) {
            containerWidth = cyContainer.clientWidth;
            containerHeight = cyContainer.clientHeight;
        }

        const containerNodes = stmtContainer.descendants();
        // Ensure the number of containerNodes does not exceed nodesLimit

        let stmtLayoutName = 'dagre';
        let x1 = (containerWidth / 2);
        let x2 = (containerWidth / 2);
        let y1 = 50;
        let y2 = (containerHeight - 50);
        let xSC = (containerWidth / 2);
        let ySC = (containerHeight / 2);
        stmtLayoutName = 'breadthfirst';
        // if (containerNodes.length < 5) {
        //     stmtLayoutName = 'breadthfirst';
        //
        // } else if (containerNodes.length < 10) {
        //     stmtLayoutName = 'dagre';
        // } else {
        //     stmtLayoutName = 'fcose';
        //     x1 = 50;
        //     x2 = 50;
        // }

        if (cyContainer && statement1.length > 0 && statement2.length > 0) {

            console.log("containerHeight: ", containerHeight)
            console.log("containerWidth: ", containerWidth)

            statement1.position({x: x1, y: y1});
            statement2.position({x: x2, y: y2});

            console.log('Statement1 positioned at:', statement1.position());
            console.log('Statement2 positioned at:', statement2.position());
        }


        containerNodes.layout({name: stmtLayoutName}).run();
        cy.center(containerNodes);
        // cy.fit(containerNodes);

        console.log('Applying coordinates and height for StatementContainer');
        console.log("containerHeight: ", containerHeight)
        console.log("containerWidth: ", containerWidth)
        cy.fit();
        stmtContainer.position({
            x: containerWidth / 2,
            y: containerHeight / 2
        });

        console.log('StatementContainer positioned at:', stmtContainer.position());

    }

    function resetAndCenterCy(cy: cytoscape.Core) {
        console.log('Resetting Cytoscape view');
        cy.resize();
        cy.fit();
        cy.center();
    }

    function runLayout(cy: cytoscape.Core, layoutName: string) {
        console.log(`Running layout: ${layoutName}`);
        const layout = cy.layout(layouts[layoutName]);
        if (layoutName === 'preset') {
            applyPresetLayout(cy);
            cy.fit();
        } else {
            layout.run();
            resetAndCenterCy(cy);
        }

    }

    useEffect(() => {
        if (reload && onLayoutApplied) {
            console.log('Reloading layout');
            onLayoutApplied();
        }
    }, [reload, onLayoutApplied]);

    useEffect(() => {
        const cy = cyRef.current;
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }
        if (nodesLimit > initialNodesNum)
            handleNodeHighlight(cy, latestNodeId);

    }, [elements, latestNodeId, nodesLimit]);

    useEffect(() => {
        const cy = cyRef.current;
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }
        updateElements(cy, elements);
    }, [elements, layoutName]);

    useEffect(() => {
        const cy = cyRef.current;
        if (!cy) {
            console.warn('Cytoscape instance is null');
            return;
        }
        runLayout(cy, layoutName);

    }, [layoutName, reload]);

    if (loading) {
        console.log('Loading...');
        return <div><LoadingSimple/></div>;
    }

    return (
        <CytoscapeComponent
            boxSelectionEnabled={false}
            cy={handleCy}
            elements={elements}
            layout={layout}
            style={containerStyle}
            stylesheet={cytoscapeStylesheet}
        />
    );
};

export default CytoscapeDiagram;