import axios, {AxiosError} from "axios";
import UiConfig from "./ui-config"
import AppSettings from "./app-settings";

const currentUrl = window.location.href;
// const is_dev = currentUrl.search("localhost") >= 0;
const is_dev = AppSettings.useDevBackEnd;
let baseUrl;
let xApiKey = process.env.REACT_APP_XAPIKEY;

// todo: something is wrong with api.personalitymap.io, fix it later
is_dev ? baseUrl = "https://abj273e2b3.execute-api.us-east-2.amazonaws.com/stage" : baseUrl = "https://5ixk91xo03.execute-api.us-east-2.amazonaws.com/stage/"
is_dev ? xApiKey = "stage-" + xApiKey : xApiKey = '' + xApiKey
const apiClient = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-type": "application/json",
        "x-api-key": xApiKey,

    },
    timeout: 280000, // 280 seconds timeout, same as for backend lambda
});
// Adding response interceptor to suppress logging for 404 errors

let retryCount = 0;
apiClient.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        retryCount++;
        console.log(`Retry attempt #${retryCount}`);
        if (error.response) {
            console.log(`Interceptor: Received response status ${error.response.status}`);
            if (error.response.status !== 404) {
                console.error({
                    message: error.message,
                    name: error.name,
                    code: error.code,
                    config: error.config,
                    response: error.response.data,
                    status: error.response.status,
                });
            }
        } else if (error.request) {
            console.error("Interceptor: No response received from server", {
                message: error.message,
                request: error.request,
            });
        } else {
            console.error("Interceptor: Error in setting up the request", error.message);
        }
        return Promise.reject(error);
    }
);

export default apiClient;
