import React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style.scss";

import QuestionSearch from "./components/question-search.component";
import Question from "./components/question.component";
import {withRouter} from "./common/with-router";
import {promptForQPage} from "./components/UIElements";
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import NotFoundPage from "./static/not-found-page";
import Terms from "./static/terms";
import Privacy from "./static/privacy";
import About from "./static/about";
import {UserProvider} from "./context/UserProvider/UserProvider";
import TopNav from "./components/authAndNav/TopNav";


const App = () => {
    const {route} = useAuthenticator(context => [context.route]);

    return (
        <UserProvider>
            <div className="wrapper d-flex flex-column position-relative">
                <TopNav/>
                <div className="container mt-3 flex-grow-1">
                    <Routes>
                        <Route path="/"
                               element={<QuestionSearch originalSearch={null}/>}/>
                        <Route path="/questions/:id"
                               element={route === 'authenticated' ? <Question/> :
                                   <Authenticator initialState={"signUp"} components={promptForQPage}/>}/>
                        <Route path="/terms" element={<Terms/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/privacy-policy" element={<Privacy/>}/>
                        <Route path="/404" element={<NotFoundPage/>}/>
                        <Route path="*" element={<NotFoundPage/>}/>
                    </Routes>
                </div>
            </div>
        </UserProvider>
    );
}

export default withRouter(App);
